import { useState } from "react";
import styled from "styled-components";

import HeroSliderV1 from "library/heroslider/HeroSliderV1";

const PageWrapper = styled.div`
margin: 0 auto;
width: 100%;
height: auto;
display: flex;
position: relative;

.hero-area {
    margin: 0 auto;
    width: 100%;
    height: 80vh;
    display: flex;
    position: relative;
}
`;

const Welcome = () => {

    const [ slideData, setSlideData ] = useState([
        {
        image: 'hero-v1/stay-inspired-1-1.jpg',  
        title: "Stay inspired. Stay informed. Stay successful!",
        description: "Get constant business inspirations from the best in the industry from your news feed in a true social platform built ground-up for business! Stay ahead of the competition and lead your industry" 
        },
        {
        image: 'hero-v1/lead-your-space-1-1.jpg',
        title: "Lead your industry. Share your experience. Share your story!",
        description: "Inspire others with lessons from your industry experiences. Learn from the successes and failures of others. Connect with influencers in your industry"
        },
        {
        image: 'hero-v1/success-best-practices-1-1.jpg',
        title: "Stay up-to-date with best practices in your industry.",
        description: "Become a leader in your industry by staying up-to-date with best practices in your industry. Share best practices that will help millions of business leaders or professionals like you."
        },
        
      ]);
    

    return (    
            <PageWrapper>
                <div className="hero-area">
                <HeroSliderV1 data={ [ ...slideData ] } maxTransSlides={ 5 } />
                </div>
                <div className="page-content__main"></div>
            </PageWrapper>
        

     );
}
 
export default Welcome;